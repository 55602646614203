export const KNOWN_CODES = [
  "STIFMO",
  "STIFBI",
  "STIHCO",
  "STICPR",
  "STISDE",
  "STICCO",
  "STIFSO",
  "STIFTR",
  "TAMHDA",
  "TAMBDA",
  "TAMCDA",
  "TAMSMO",
  "TAMNMO",
  "TAMKDI",
  "TAMKWE",
  "PREHNE",
  "TOYNNE",
  "PREHHA",
  "PRENHA",
  "PREVHA",
  "PRECHB",
  "PRENME",
  "10118LEIARA",
  "CHRIWA",
  "LEIARA",
  "LEICAB",
  "LEICCA",
  "LEICRA",
  "LEIFWE",
  "LEIHRA",
  "LEILRA",
  "LEINCA",
  "LEITCA",
  "LEITHR",
  "LEITR2",
  "LEITRA",
  "LEITRL",
  "LEITWE",
  "LEIVRA",
  "LETCRA",
  "HENCAT",
  "HENCDU",
  "HENFAT",
  "HENHWO",
  "HENJDU",
  "HENLAL",
  "HENLAT",
  "HENLDU",
  "HENMMO",
  "HENPMA",
  "HENPRO",
  "CANFCL",
  "CANLST",
  "CANBCL",
  "CANCGR",
  "CANCWE",
  "CANCOX",
  "CANHPO",
  "CANCCL",
  "CANFPA",
  "CANCGE",
  "CANPGR",
  "CANTVI",
  "CANTMO",
  "CANNPA",
  "CANDWE",
  "CANPJA",
  "CANPCC",
  "CANNGR",
  "CANNJA",
  "CANNOX",
  "GREIGR",
  "CANCLR",
  "CANBVI",
  "CANCCS",
];
