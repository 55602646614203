export const config = {
    title: "New Customer",
    company_logo: "hennessy.png",
    logo_placements: ["Left Chest", "Right Sleeve"],
    show_modification_thumbnail: true,
    minimum_apparel_order: true,
    render_logo_preview: true,
    embroideries: {
      hat: ["Hennessy Porsche", "Porsche Atlanta NW", "Cadillac", "Hennessy Ford", "Hennessy Ford Lincoln", "Hennessy Honda", "Hennessy Mazda GMC", "Porsche North Atl", "Porsche"],          
      mens:["Hennessy Porsche", "Porsche Atlanta NW", "Cadillac", "Hennessy Ford", "Hennessy Ford Lincoln", "Hennessy Honda", "Hennessy Mazda GMC", "Porsche North Atl", "Porsche"],     
      womens: ["Hennessy Porsche", "Porsche Atlanta NW", "Cadillac", "Hennessy Ford", "Hennessy Ford Lincoln", "Hennessy Honda", "Hennessy Mazda GMC", "Porsche North Atl", "Porsche"],        
      accessory: ["Hennessy Porsche", "Porsche Atlanta NW", "Cadillac", "Hennessy Ford", "Hennessy Ford Lincoln", "Hennessy Honda", "Hennessy Mazda GMC", "Porsche North Atl", "Porsche"], 
      customs: [], 
    },
    stores: [
      "Hennessy Jaguar Land Rover, 3040 Piedmont Rd NE, Atlanta, GA 30305",
      "Hennessy Cadillac, 3377 Satellite Blvd, Duluth, GA 30096",
      "Hennessy Ford, 5675 Peachtree Industrial Blvd, Atlanta, GA 30341",
      "Hennessy Honda, 8931 Highway 92, Woodstock, GA 30189",
      "Hennessy Jaguar, 3423 Old Norcross Rd, Duluth, GA 30096",
      "Hennessy Land Rover, 1505 Mansell Rd, Alpharetta, GA 30004",
      "Hennessy Lexus, 5955 Peachtree Industrial Blvd, Atlanta, GA 30341",
      "Hennessy Lexus Duluth, 3383 Satellite Blvd, Duluth, GA 30096",
      "Hennessy Mazda Buick GMC, 7261 Jonesboro Rd, Morrow, GA 30260",
      "Hennessy Porsche Marietta, 2501 Windy Hill Rd SE, Marietta, GA 30067",
      "Hennessy Porsche, 990 Mansell Rd, Roswell, GA 30076",     
    ],
  };
  