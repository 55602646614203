import { CatalogItem } from "../lib/interfaces";
export const NewCustomerCatalog: CatalogItem[] = [
  {
    code: "J317",
    name: "Port Authority® Jacket",
    fullname: "Port Authority® Core Soft Shell Jacket",
    colors: ["Black", "Battleship Grey", "Rich Red", "True Royal"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 37.99,
      Medium: 37.99,
      Large: 37.99,
      XLarge: 37.99,
      "2XLarge": 39.99,
      "3XLarge": 41.99,
      "4XLarge": 43.99,
      "5XLarge": 45.99,
      "6XLarge": 47.99,
    },
  },
  {
    code: "J331",
    name: "Port Authority® Jacket",
    fullname: "Port Authority® All-Conditions Jacket",
    colors: ["Black", "Direct Blue", "True Navy"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 64.99,
      Medium: 64.99,
      Large: 64.99,
      XLarge: 64.99,
      "2XLarge": 66.99,
      "3XLarge": 68.99,
      "4XLarge": 70.99,
    },
  },
  {
    code: "J333",
    name: "Port Authority® Jacket",
    fullname: "Port Authority® Torrent Waterproof Jacket",
    colors: ["Black", "Engine Red", "Magnet", "True Royal"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 56.99,
      Medium: 56.99,
      Large: 56.99,
      XLarge: 56.99,
      "2XLarge": 58.99,
      "3XLarge": 60.99,
      "4XLarge": 62.99,      
    },
  },
  {
    code: "NF0A7V4F",
    name: "The North Face® Jacket",
    fullname: "The North Face® Down Hybrid Jacket",
    colors: ["TNF Black", "TNF Med Gry Hthr"],
    type: "mens",
    default_color: "TNF Black",
    sizes: {
      Small: 199.00,
      Medium: 199.00,
      Large: 199.00,
      XLarge: 199.00,
      "2XLarge": 201.00,
      "3XLarge": 203.00,
    },
  },
  {
    code: "ST740",
    name: "Sport-Tek® UV Polo",
    fullname: "Sport-Tek® UV Micropique Polo",
    colors: ["Black", "Deep Red", "Graphite", "True Royal"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 22.99,
      Medium: 22.99,
      Large: 22.99,
      XLarge: 22.99,
      "2XLarge": 24.99,
      "3XLarge": 26.99,
      "4XLarge": 28.99,
    },
  },
  {
    code: "OG101",
    name: "OGIO® - Caliber2.0 Polo",
    fullname: "OGIO® - Caliber2.0 Polo",
    colors: ["Blacktop", "Diesel Grey", "Electric Blue", "Signal Red"],
    type: "mens",
    default_color: "Blacktop",
    sizes: {
      Small: 33.99,
      Medium: 33.99,
      Large: 33.99,
      XLarge: 33.99,
      "2XLarge": 35.99,
      "3XLarge": 37.99,
      "4XLarge": 39.99,
    },
  },
  {
    code: "NKDC1963",
    name: "Nike® Dri-FIT Polo Shirt",
    fullname: "Nike Dri-FIT Micro Pique 2.0 Polo",
    colors: [ "Anthracite", "Black","Game Royal","University Red"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 39.99,
      Medium: 39.99,
      Large: 39.99,
      XLarge: 39.99,
      "2XLarge": 41.99,
      "3XLarge": 43.99,
      "4XLarge": 45.99,
      LT: 45.99,
      XLT: 47.99,
      "2XLT": 49.99,
      "3XLT": 51.99,
      "4XLT": 53.99,
    },
  },
  {
    code: "TM1MAA370",
    name: "TravisMathew Solid Polo",
    fullname: "TravisMathew Cabana Solid Polo",
    colors: [ "Black", "Classic Blue", "Crimson", "Stormy Grey"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 79.99,
      Medium: 79.99,
      Large: 79.99,
      XLarge: 79.99,
      "2XLarge": 81.99,
      "3XLarge": 83.99,
    },
  },
  {
    code: "S608",
    name: "Port Authority® Shirt",
    fullname: "Port Authority® Long Sleeve Easy Care Shirt",
    colors: ["Black", "Red", "Royal", "Steel Grey"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 34.99,
      Medium: 34.99,
      Large: 34.99,
      XLarge: 34.99,
      "2XLarge": 36.99,
      "3XLarge": 38.99,
      "4XLarge": 40.99,
      "5XLarge": 42.99,
      "6XLarge": 44.99,
    },
  },
  {
    code: "OG1002",
    name: "OGIO® Woven Shirt",
    fullname: "OGIO® Commuter Woven Shirt",
    colors: [ "Blacktop", "Gear Grey Hthr", "Metal Blue Hthr", "White"],
    type: "mens",
    default_color: "Blacktop",
    sizes: {
      Small: 46.99,
      Medium: 46.99,
      Large: 46.99,
      XLarge: 46.99,
      "2XLarge": 48.99,
      "3XLarge": 50.99,
      "4XLarge": 52.99,
    },
  },  
  {
    code: "BB18002",
    name: "Brooks Brothers® Shirt",
    fullname: "Brooks Brothers® Wrinkle-Free Nailhead Shirt",
    colors: [ "Cobalt Blue", "Deep Black", "Navy Blazer", "White"],
    type: "mens",
    default_color: "Deep Black",
    sizes: {
      Small: 65.99,
      Medium: 65.99,
      Large: 65.99,
      XLarge: 65.99,
      "2XLarge": 67.99,
      "3XLarge": 69.99,
      "4XLarge": 71.99,
    },
  },  
  {
    code: "K584",
    name: "Port Authority® 1/4-Zip",
    fullname: "Port Authority® Silk Touch™ 1/4-Zip",
    colors: [ "Black", "Red", "Royal", "Steel Grey"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 29.99,
      Medium: 29.99,
      Large: 29.99,
      XLarge: 29.99,
      "2XLarge": 31.99,
      "3XLarge": 33.99,
      "4XLarge": 35.99,
    },
  },  
  {
    code: "NKBV6044",
    name: "Nike 1/2-Zip Cover-Up",
    fullname: "Nike Dry 1/2-Zip Cover-Up",
    colors: [ "Black", "Black Heather", "Gym Blue"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 64.99,
      Medium: 64.99,
      Large: 64.99,
      XLarge: 64.99,
      "2XLarge": 66.99,
      "3XLarge": 68.99,
      "4XLarge": 70.99,
    },
  },  
  {
    code: "TM1MY397",
    name: "TravisMathew 1/4-Zip",
    fullname: "TravisMathew Coto Performance 1/4-Zip",
    colors: [ "Black", "Brilliant Blue Hthr", "Quiet Shade Grey Hthr", "Vintage Indigo"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 79.99,
      Medium: 79.99,
      Large: 79.99,
      XLarge: 79.99,
      "2XLarge": 81.99,
      "3XLarge": 83.99,  
    },
  },
  {
    code: "HENCAT-33P-497838B",
    name: "Soft Drive Out Plates",
    fullname: "Soft Plates",
    colors: ["Black"],
    type: "customs",
    default_color: "Black",
    discount: [
      { quantity: 1000, price: 0.00 },
      { quantity: 2500, price: 0.00 },
    ],
    sizes: {
      default: 0.00,
    },
  }, 
  {
    code: "HENCAT-33P-497628B",
    name: "Soft Drive Out Plates",
    fullname: "Soft Plates",
    colors: ["Black"],
    type: "customs",
    default_color: "Black",
    discount: [
      { quantity: 1000, price: 0.00 },
      { quantity: 2500, price: 0.00 },
    ],
    sizes: {
      default: 0.00,
    },
  }, 
  {
    code: "HENLDU-34MLF-366502A",
    name: "Metal Frames",
    fullname: "Metal Frames",
    colors: ["Metal"],
    type: "customs",
    default_color: "Metal",
    discount: [
      { quantity: 1000, price: 0.00 },
      { quantity: 2500, price: 0.00 },
    ],
    sizes: {
      default: 0.00,
    },
  }, 
  {
    code: "HENLAT-34MLF-379167A",
    name: "Metal Frames",
    fullname: "Metal Frames",
    colors: ["Metal"],
    type: "customs",
    default_color: "Metal",
    discount: [
      { quantity: 1000, price: 0.00 },
      { quantity: 2500, price: 0.00 },
    ],
    sizes: {
      default: 0.00,
    },
  }, 
  {
    code: "HENLAL-34CF-459349A",
    name: "Chrome Faced Frames",
    fullname: "Chrome Faced Frames",
    colors: ["Chrome Faced"],
    type: "customs",
    default_color: "Chrome Faced",
    discount: [
      { quantity: 1000, price: 0.00 },
      { quantity: 2500, price: 0.00 },
    ],
    sizes: {
      default: 0.00,
    },
  }, 
  {
    code: "HENLAL-33G24-373653A",
    name: "Soft Drive Out Plates",
    fullname: "Soft Plates",
    colors: ["Black"],
    type: "customs",
    default_color: "Black",
    discount: [
      { quantity: 1000, price: 0.00 },
      { quantity: 2500, price: 0.00 },
    ],
    sizes: {
      default: 0.00,
    },
  }, 
  {
    code: "HENLAT-33G24-367503A",
    name: "Soft Drive Out Plates",
    fullname: "Soft Plates",
    colors: ["White"],
    type: "customs",
    default_color: "White",
    discount: [
      { quantity: 1000, price: 0.00 },
      { quantity: 2500, price: 0.00 },
    ],
    sizes: {
      default: 0.00,
    },
  }, 
  {
    code: "HENLDU-33G24-373661B",
    name: "Soft Drive Out Plates",
    fullname: "Soft Plates",
    colors: ["White"],
    type: "customs",
    default_color: "White",
    discount: [
      { quantity: 1000, price: 0.00 },
      { quantity: 2500, price: 0.00 },
    ],
    sizes: {
      default: 0.00,
    },
  }, 
  {
    code: "1640",
    name: "Logo Key Chain",
    fullname: "Brushed Zinc/Leather/Big Logo Key Chain",
    colors: ["Black"],
    type: "accessory",
    default_color: "Black",
    sizes: {
      Default: 0.00,
    },
  },
  {
    code: "HENLAT-GPLLKR-456506L",
    name: "Leather Loop Keychain",
    fullname: "Leather Loop Metal Keychain",
    colors: ["Black Leather"],
    type: "accessory",
    default_color: "Black Leather",
    sizes: {
      Default: 0.00,
    },
  },   
];
